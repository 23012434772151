import './bootstrap';

import.meta.glob([
    '../assets/images/**'
])

import '@awesome.me/kit-939b169153';

import Chart from 'chart.js/auto';
window.Chart = Chart;

import tippy from 'tippy.js';
tippy.setDefaultProps({theme: 'groeiplan'});
window.tippy = tippy;

import 'laravel-datatables-vite';
// import DataTable from 'datatables.net-bs5';
// import 'datatables.net-responsive-bs5';

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';


window.Alpine = Alpine;

Livewire.start()

Trix.config.blockAttributes.heading1.tagName = "h3"
